@import '../../../styles/config.scss';


.inputDate {
    padding: 10px 20px;

    & > * {
        border: none;
    }
}
