.mikaTextInputWrapper{
  min-height: 50px;
  margin-bottom: 20px;
  position: relative;
  label{
    padding: 5px 10px;
  }
  input{
    width: 100%;
    //min-height: 100%;
    font-size: 16px;
    color: var(--main);
    padding: 15px;
    &:focus{
      outline: none;
    }
  }
}