@import '../../styles/config.scss';

.validationMessage {
    display: flex;
    align-items: center;
    height: 30px;
    font-size: 12px;
    line-height: 17px;
    color: var(--grey);
    margin-top: 5px;
    position: absolute;
    bottom: -28px;
}

.empty {
    display: none;
}

.success {
    color: green;
    // display: block;
}

.undefined {
    visibility: hidden;
    // display: block;
}

.error {
    color: var(--red);
    // display: block;
}

.standard {
    // display: block;
    //color: white;
}
