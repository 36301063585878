@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
* {
    font-family: 'Roboto', sans-serif;
}

.big-text {
    font-size: 33px;
}
.medium-text {
    font-size: 18px;
}
.small-text {
    font-size: 18px;
}
.mini-text {
    font-size: 12px;
}