.gallery{
  width: 100%;
  position: relative;
  .arrowIcons{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &.left{
      left: 15px;
    }
    &.right{
      transform: translateY(-50%) rotate(180deg);
      right: 15px;
    }
  }
  .mainImage{
    width: 100%;
    height: 400px;
    max-height: 400px;
    object-fit: cover;
  }
}
.impressionQuestionsContainer{
  .questionContainer{
    width: 80%;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: solid 1px var(--purple);
    border-radius: 15px;
    p{
      width: 50%;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0;
      padding: 20px;
    }
    .starRatingContainer{
      width: 40%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 69px;
      .starWrapper{
        width: 30px;
        height: 30px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      &.averageContainer{
        padding-inline: 20px;
        justify-content: flex-end;
        p{
          line-height: 10px;
          margin-top: 5px;
          width: fit-content;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .impressionQuestionsContainer{
    .questionContainer{
      width: 100%;
      margin: 10px 0;
      //display: flex;
      //justify-content: space-between;
      //align-items: flex-start;
      //border: solid 1px var(--purple);
      //border-radius: 15px;
      p{
        width: 50%;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0;
        padding: 20px;
      }
      .starRatingContainer{
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 69px;
        .starWrapper{
          width: 30px;
          height: 30px;
          margin-right: 5px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}