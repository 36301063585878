.select-wrapper{
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        color: #707070;
        font-weight: 500;
        font-size: 16px;
    }
    &.default{
        *{
            --mainColor: var(--light-blue);
            --mainBgColor: white;
            --selectedValueBgColor: var(--purple);
        }
    }
    &.orange{
        *{
            --mainColor: var(--orange);
            --mainBgColor: white;
            --selectedValueBgColor: var(--orange);
        }
    }
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    .select{
        width: 100%;
        height: 100%;
        position: relative;
        user-select: none;
        background-color: var(--mainBgColor);
        .selected-value-container{
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 20px 12px 12px;
            border: solid 1px var(--mainColor);
            position: relative;
            z-index: 1;
            background-color: inherit;
            border-radius: 10px;
        }
        .options-container{
            padding-top: 10px;
            position: absolute;
            top: calc(100% - 5px);
            left: 0;
            z-index: 10;
            width: 100%;
            height: max-content;
            max-height: 200px;
            overflow-y: auto;
            &::-webkit-scrollbar{
                width: 4px;
                height: 4px;
            }
            background-color: inherit;
            border: solid 1px var(--mainColor);
            border-radius: 0 0 10px 10px;
            .option{
                width: 100% !important;
                padding: 10px 15px;
                font-size: 15px;
                &:hover , &.selectedOption{
                    background-color: var(--mainColor);
                }
            }
        }
    }
    .rotatedArrow{
        transition: 250ms;
        transform: rotate(180deg);
    }
    .children-container{
        margin-bottom: 5px;
        color: var(--dark-blue);
        font-weight: 500;
    }
    .clearValueIcon{
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        z-index: 1;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            transform: rotate(-45deg);
        }
    }
    .searchBarContainer{
        padding-inline: 10px !important;
        &:hover{
            background-color: white !important;
        }
        input{
            border-radius: 5px;
            border: solid 1px var(--mainColor);
            width: 100%;
            padding: 5px;
        }
    }
}