@import '../../App.scss';

.createProjectModalWrapper{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200vw;
  height: 200vh;
  z-index: 11111;
  background-color: rgba(0, 0, 0, 0.2);
  .forInnerStock{
    padding: 10px 30px;
    display: flex;
    justify-content: start;
    align-items: center;
    input{
      margin: 5px;
      width: 20px;
      height: 20px;
    }
  }
  .select-wrapper{
    .x-sign{
      top: inherit !important;
      right: inherit !important;
      position: static !important;
    }
  }
  .createProjectModalContainer{
    max-height: 100vh;
    height: fit-content;
    width: 60vw;
    border-radius: 15px;
    border: solid 1px #a5c9ff;
    background-color: white;
    overflow: auto;

    @include breakpoint(sm){
      max-height: 100vh;
      width: 80vw;
    }

    @include breakpoint(xs){
      max-height: 100vh;
      width: 100vw;
    }
    .createProjectModal{
      width: 100%;
      height: 100%;
      position: relative;
      padding: 15px;
      .x-sign{
        position: absolute;
        top: 15px;
        right: 15px;
        width: fit-content;
        height: fit-content;
        cursor: pointer;
        z-index: 2;
        img{
          transform: rotate(45deg);
        }
      }
      .projectRow{
        position: relative;
        margin-top: 5px;
        border-radius: 8px;
        &.collapsed{
          height: 100px;
          overflow: hidden;
          &:hover{
            background-color: rgba(128, 128, 128, 0.3);
          }
        }
        .x-sign{
          position: absolute;
          top: 5px;
          right: 15px;
          width: fit-content;
          height: fit-content;
          cursor: pointer;
          @include breakpoint(sm){
            top:0px;
          }
          img{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            transform: rotate(45deg);
          }
        }
        .paddingMobile{
          @include breakpoint(xs){
            padding: 0 !important;
          }
        }

      }
      .mobileAlignment{
        @include breakpoint(xs){
          flex-direction: column;
        }

        .marginStyle{
          @include breakpoint(xs){
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
      .productLocationInputContainer{
        height: fit-content;
        margin: 0;
        input, textarea{
          border: solid 1px var(--light-blue);
          border-radius: 10px;
          padding: 0 12px;
        }
        label{
          padding: 4px 12px;
          transition: 200ms;
          font-weight: 500;
          color: var(--dark-blue);
        }
        .pinLogoForMeasure{
          width: 60px;
          height: calc(100% - 39px);
          position: absolute;
          bottom: 2px;
          right: 1px;
          background-color: #EEEEEE;
          padding: 15px;
          z-index: 1;
          border-radius: 0 9px 9px 0;
          cursor: pointer;
        }
      }
    }
  }
}

.projectModalButtonContainer{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  text-align: right;
  .buttonWrapper{
    margin: auto;
    width: 50%;
    @include breakpoint(xs){
      width: 100%;
      padding: 0 !important;
    }
  }
  @include breakpoint(xs){
    flex-direction: column;
  }
}

@include breakpoint(xs){
  .textarea-wrapper.mobile{
    margin-top: 20px;
  }
}

.projectInputContainer{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .projectInput{
    width: 50%;
    @include breakpoint(xs){
      width: 100%;
      padding: 0 !important;
      margin-bottom: 10px;
      .input-wrapper{
        padding: 0 !important;
        margin: 0!important;
      }
      .select-input{
        margin: 0 !important;
      }
    }
  }

}

.input-wrapper{
  @include breakpoint(sm){
    margin: 0 !important;
  }
}

.projectComment{
  @include breakpoint(sm){
    height: 85%;
  }
}

.dateInputOfProjectCreationModal{
  width: 100%;
  height: 50px !important;
  border-radius: 10px;
  border: solid 1px var(--light-blue);
  padding: 10px !important;
}
.projectContainerWrapper{
  display: flex;
  flex-direction: column-reverse;
}