.mikaInputFileWrapper{
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 8px 16px #18327314;
  min-height: 60px;
  .imgHoverContainer{
    position: absolute;
    bottom: 100%;
    max-width: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: #f4f4f4;
    gap: 5px;
    padding: 5px;
    border: solid 1px var(--dark-blue);
    border-radius: 5px;
    animation: fadeIn 300ms forwards;
    z-index: 1111111;
    img{
      background-color: white;
      width: 100px;
      height: 100px;
      object-fit: contain;
      object-position: center;
      border-radius: 5px;
    }
  }
  p{
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-blue);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  #fileInput{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }
  img{
    width: 20px;
    margin: 4px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}