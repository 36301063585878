@import '../../styles/config.scss';

.input-wrapper {
    position: relative;
    display: flex;
    width: auto;
    min-height: 50px;
    flex-wrap: wrap;
    vertical-align: top;
    text-align: left;
    margin: 10px 0;

    ::-ms-clear {
        display: none;
    }

    label {
        margin-bottom: 0;    
        color: var(--dark-blue);
        font-weight: 500;
    }

    &.textarea-input {
        height: unset;
        min-height: unset;
        max-height: unset;

        label:first-child {
            margin-bottom: 8px;
        }
    }

    &.radio-input {

        height: auto;
        max-height: 100px;

        label:first-child {
            margin-bottom: 3px;
        }
    }

    &.file-input {
        color: var(--dark);
        background-color: var(--button-grey);
        font-size: 16px;
        font-weight: 700;
        padding: 15px 20px;
        min-width: 200px;
        border: 1px solid var(--light-blue);
        text-transform: uppercase;
        width: 100%;
        border-radius: 10px;
        margin: 0;
        text-align: center;
    }

    &.input-error {
        label {
            color: var(--red);
            position: absolute;
            bottom: -22px;
            font-size: 12px;
        }
        input,
        textarea {
            border-color: var(--red);
        }
    }
    input{
        background-color: white;
    }
}

.form {
    .input-wrapper {
        margin-bottom: 40px;
    }

    .input-wrapper.toggle-checkbox {
        padding-top: 20px;
        align-items: center;
    }
    .input-wrapper.toggle-input {
        padding-top: 20px;
        align-items: center;
        margin-left: 35px;

        @media (max-width: 992px) {
            margin-left: 0;
        }
    }
}

.input-wrapper.filter-input {
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    margin-bottom: 24px;

    &.text-input,
    &.dropdown-input{
        label:first-child {
            display: none;
        }
    }

    &.radio-input {
        height: 24px;
        min-height: 24px;
        max-height: 24px;
    }

}
