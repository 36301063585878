@import '../../App.scss';

.create-user-form{
    height: 80vh;
    display: flex;
    justify-content: center;
    .permissions-container.user-mobile , .responsibilities-container.user-mobile{
        flex-direction: column;
        margin-top: 20px;
        .permission , .responsibility{
            width: 100%;
            .input-wrapper{
                margin: 0!important;
            }
        }
    }
    .titles{
        font-size: 30px !important;
    }
    .permissions-container , .responsibilities-container{
        display: flex;
        flex-wrap: wrap;
        .permission , .responsibility{
            width: 15%;
            .checkboxWrapper{
                &.selected{
                    box-shadow:0px 0px 10px  var(--dark-blue);
                }
                border: solid 1px var(--dark-blue);
                width: 90%;
                display: flex;
                align-items: center;
                padding: 10px;
                border-radius: 5px;
                justify-content: space-between;
                background-color: var(--light-purple);
                label{
                    width: fit-content;
                }
            }
        }
        .permission{
            @include breakpoint(sm){
                width: 20%;
            }
         }
        @include breakpoint(sm){
            gap: 30px;
        }

    }
    .userPasswordInputs{
        input{
            height: 49px !important;
        }
    }
    .mikaInputFileWrapper.change-avatar-input {
        margin-right: 15px;
        margin-top: 10px;
    }
    .mikaPasswordInputWrapper .togglePasswordIcon {
        height: 50px;
    }

    .create-new-text{
        @include breakpoint(sm){
            width: 300px !important;
        }
    }
}
.admin-page{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    &:not(.responsibilities):not(.equipments):not(.units){
        justify-content: space-between;
    }
    align-items: flex-start;
    padding: 20px;

    @include breakpoint(xs){
        flex-direction: column-reverse;
    }

    @include breakpoint(sm){
        padding-right: 0;
    }
    .admin-page-list{
        width: 50%;
        color: var(--dark-blue);
        padding: 20px;
        background-color: rgba(128, 128, 128, 0.171);
        border-radius: 7px;
        @include breakpoint(sm){
            margin-left: 5px;
            width: 100%;
        }
        @include breakpoint(xs){
            width: 100%;
            margin-bottom: 3rem;
        }
        .tr{
            border: none !important;
            border-bottom: solid 1px var(--light-blue) !important;
            border-radius: 0;
            .td{
                border-bottom: none !important;
                border-top: none !important;
            }
        }
        .table-responsive{
            width: max-content;
        }
        ul{
            margin: 0;
            padding-left: 0;
            li{
                padding: 20px 0 0 0;
                font-size: 24px;
                font-weight: 500;
                list-style: none;
            }
        }
    }
    .create-admin-page-form{
        width: 400px;

        .input-wrapper{
            margin: 10px 0 0 0;
            label{
                margin-bottom: 5px;
            }
            .select-input {
                height: 50px;
                margin-bottom: 40px;
                &.material-select{
                    position: relative;
                    div:nth-child(3){
                        div:first-child{
                            div:first-child{
                                position: absolute;
                            }
                        }
                    }
                }
            }
            .select-input .react-select__control {
                height: 50px;
                border: 1px solid var(--light-blue);
                border-radius: 10px;
                padding-left: 12px;
                color: var(--main);
                font-size: 16px;
                font-weight: 500;
                &> * {
                    color: var(--main)!important;
                }
            }
        }
    }
    .create-article-form, .create-type-form{
        width: 900px !important;
        position: sticky;
        top: 0;
        display: flex;
        .responsibilities-container{
            &:not(.article-type){
                padding: 20px 0 0 50px;
            }
            .responsibility{
                .checkboxWrapper{
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: row-reverse;
                    margin: 0;
                    align-items: center;
                    p , label{
                        width: fit-content;
                        margin-inline: 10px;
                        font-size: 18px;
                    }
                }
            }
        }
        .input-container{
            width: 80%;
        }
    }
    .to-article-types-button{
        padding: 10px 20px;
        border-radius: 10px;
        font-size: 18px;
        font-weight: bold;
        background-color: var(--dark-blue);
        position: absolute;
        top: 35px;
        left: 60px;
        color: white;
        transition: 200ms;
        cursor: pointer;
        z-index: 1;
        user-select: none;
        &:hover{
            color:  var(--purple);
        }
    }
    .workingDaysInput {
        width: 97%;
        margin: 15px auto !important;
    }
    .select-input{
        .react-select__control{
            border-radius: 10px;
        }
        input{
            min-height: 40px;
        }
    }
    .add.addSuppliersButton{
        position: absolute !important;
        top: 0;
        margin: 0 !important;
        right: 5px;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .remove.removeSupplierButton{
        position: absolute !important;
        top: 0;
        margin: 0 !important;
        right: 35px;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .minusHolder{
            border-radius: 50%;
            width: 18px;
            height: 18px;
            border: solid 1px var(--dark-blue);
            display: flex;
            justify-content: center;
            align-items: center;
            div{
                width: 60%;
                height: 1px;
                border-radius: 1px;
                background-color: var(--dark-blue);
                margin-top: 1px;
                margin-right: 0;
                transform: translateY(-50%);
            }
        }
    }
}
.article-page, .material-page{
    accent-color: var(--dark-blue);
    .responsibilityListContainer{
        width: 20%;
        margin-top: 30px;
            @include breakpoint(sm){
                width: 36%;
                margin-top: 0;
            }
        p{
            margin-left: 10px;
            margin-bottom: 5px;
            color: var(--dark-blue);
            font-weight: 500;
        }
    }
    .responsibilities-container{
        background-color: white;
        box-shadow: 2px 8px 16px #18327314;
        border-radius: 15px;
        padding: 0 20px;
        .responsibility{
            &:not(:last-child){
                border-bottom: solid 1px var(--light-blue);
            }
        }
        .input-wrapper{
            margin: 0 !important;
            padding: 15px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            label{
                width: fit-content;
            }
        }
    }
    .warehouseModal-header{
        font-weight: 500;
        font-size: 30px;
        text-align: left;
        color: var(--dark-blue);
    }
}
.offerConditions{
    color: var(--dark-blue);
    .quillContainer{
        margin-bottom: 50px;
        height: 30vh;
    }
    .tabletForm{
        @include breakpoint(sm){
            width: clamp(10%, 80%, 100%) !important;
        }
    }
}
.equipments-page{
    .full-height-container > div:nth-child(2) {
        height: fit-content !important;
    }
}
.auditLogWrapper{
    padding: 20px;
    .auditLogModal-wrapper{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 150vw;
        height: 150vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,0.4);
        z-index: 111111;
        .auditLogModal{
            max-height: 100vh;
            overflow: auto;
            padding: 30px;
            background-color: white;
            border-radius: 15px;
            position: relative;
            min-width: 300px;
            .x-sign{
                transform: rotate(45deg);
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }
            .modalTitle{
                font-size: 24px;
                font-weight: 500;
                color: var(--dark-blue);
            }
            .auditLogContent{
                display: flex;
                .valueContainers{
                    width: 40vw;
                    .cardTitle{
                        font-size: 34px;
                        font-weight: 400;
                        color: var(--dark-blue);
                    }
                    .valueRowContainer{
                        background-color: var(--light-blue);
                        padding: 0 15px;
                        border-radius: 15px;
                        margin: 20px;
                    }
                    .valueRow{
                        display: flex;
                        justify-content: space-between;
                        position: relative;
                        &::before{
                            content: '';
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                            height: 1px;
                            width: 100%;
                            background-color: var(--dark-blue);
                        }
                        &:last-child{
                            &::before{
                                display: none;
                            }
                        }
                        p{
                            width: 50%;
                            text-transform: capitalize;
                            font-weight: 500;
                            margin: 0;
                            padding: 10px 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

.checkboxWrapper{
    .index{
        margin: 0;
        position: absolute;
        top: 50%;
        right: 32px;
        transform: translate(-50%, -50%);
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: var(--dark-blue);
        color: white !important;
        padding-top: 2px;
        text-align: center;
    }
}

.productionSettingsPage{
    flex-direction: column;
    .pageTitle{
        color: var(--dark-blue);
        font-size: 30px;
        font-weight: 500;
    }
    .productionSettingsInputContainer{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .checkboxWrapper{
            width: 19%;
            margin-right: 1%;
            padding: 10px;
            flex-wrap: nowrap;
            background-color: var(--light-purple);
            border: solid 2px var(--dark-blue);
            border-radius: 10px;
            label:last-child{
                width: fit-content;
            }
        }
    }
}