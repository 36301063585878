.agreements {
  .photo-list {
    text-align: left;
    font-size: 18px;
    color: var(--dark-blue);
    text-transform: uppercase;
    font-weight: bold;
  }
  .agreement-creation-form {
    h1.main-title {
      font-size: 18px;
      color: var(--dark-blue);
    }
    .input-container {
      .input-wrapper {
        label {
          text-transform: uppercase;
          color: var(--dark-blue);
          font-weight: 500;
        }
      }
    }
  }
  .create-agreements-form {
    flex-wrap: wrap;
    .input-wrapper {
      width: 500px;
    }
  }
  .file-input-container {
    width: 46.5%;
    div.row {
      .input-file-wrapper {
        p {
          width: 50%;
          text-transform: uppercase;
          color: var(--dark-blue);
          font-weight: 500;
        }
        .file-input {
          background: none;
          position: relative;
          .InputFile_inputFileButton__1-sHh {
            display: none;
          }
          .InputFile_inputFileName__2-614 {
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
  .agreement-status-dropdown {
    height: 50px;
  }
  .simple-text {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--dark-blue);
    font-weight: 500;
  }
}
.print-container {
  .printableAgreement {
    display: none;
  }
}
.printableAgreement-wrapper {
  width: 100%;
  height: 100%;
  padding: 20px;
  .pdfTitle {
    font-size: 38px;
    color: var(--dark-blue);
    font-weight: 600;
  }
  .agreementContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .agreementField {
      width: 48%;
      margin-top: 50px;
      border-bottom: solid 2px var(--dark-blue);
      padding: 10px;
      font-size: 18px;
      position: relative;
      p {
        margin: 0;
        &.fieldLabel {
          width: 100%;
          margin-left: -10px;
          font-weight: 500;
        }
      }
    }
    .commentField {
      width: 100%;
      &.fieldLabel {
        margin: 30px 0 20px 10px;
        font-weight: 500;
        font-size: 18px;
      }
      &:not(.fieldLabel) {
        padding: 10px;
        border-radius: 10px;
        border: solid 2px var(--dark-blue);
        min-height: 300px;
      }
    }
  }
}
.printButton {
  border: none;
  background-color: transparent;
  font-size: 18px;
  color: var(--dark-blue);
  img {
    width: 20px;
    height: 20px;
  }
}

.m-bottom {
  margin-bottom: 40px;
}
.test {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table .tr .th,
.table .tr .td {
  width: 23%;
  min-width: 10%;
}

.small-tablet-width-for-agreements{
  @media (min-width: 768px) and (max-width: 920px) {
    width: 200px;
  }
}
.agreements-calendar-container{
  padding: 5px;
  position: relative;
  .fc-daygrid-event{
    width: 100%;
    &:hover{
      background-color: unset;
    }
  }
  .event-container{
    width: 100%;
    background-color: var(--light-blue);
    overflow: hidden;
    padding: 0 5px;
    border-radius: 5px;
    &.export{
      background-color: var(--orange);
    }
    p{
      width: 100%;
      font-size: 14px;
    }
  }
}
.addTaskButton{
  width: fit-content;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 110px;
  background-color: #2C3E50;
  border-radius: 5px;
  transition: 200ms;
  p{
    margin: 0;
    padding: 8px;
    width: fit-content;
    font-weight: 500;
    color: white;
  }
}
.showYearlyProductionButtonAgreement{
  width: fit-content;
  cursor: pointer;
  position: absolute;
  top: 5px;
  background-color: #2C3E50;
  border-radius: 5px;
  transition: 200ms;
  input[type=month]{
    height: 40px;
    border: solid 1px var(--dark);
    padding: 0 10px;
    font-size: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    color: #2C3E50;
  }
  .filterMonths{
    height: 40px;
    padding: 0 20px;
    background-color: #2C3E50;
    border: solid 1px #2C3E50;
    font-weight: 500;
    color: white;
    border-radius: 0 8px 8px 0;
  }
  &:hover{
    background-color: #272F3AFF;
  }
  &.show{
    left: 15px;
  }
  &.hide{
    left: 200px;
  }
  p{
    margin: 0;
    padding: 8px;
    width: fit-content;
    font-weight: 500;
    color: white;
  }
}
.agreementExtraInfoModal-wrapper{
  width: 150vw;
  height: 150vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 10;
  .agreementExtraInfoModal{
    background-color: #f4f4f4;
    position: relative;
    border-radius: 8px;
    //padding: 2px;
    min-width: 600px;
    min-height: 150px;
    max-height: 90vh;
    overflow: auto;
    display: flex;
    align-items: start;
    justify-content: center;
    .x-sign-container{
      position: absolute;
      transform: rotate(45deg);
      top: 5px;
      right: 15px;
    }
    .agreementContent{
      width: 100%;
      min-height: 150px;
      display: flex;
      flex-direction: column;
      .simpleLoader{
        margin: auto;
      }
      .titleContainer{
        background-color: var(--light-blue);
        padding: 10px 5px;
        border-radius: 8px;
        width: 100%;
        p{
          margin: 0;
          font-weight: 500;
        }
      }
    }
  }
}

.taskCreationModal-wrapper{
  width: 150vw;
  height: 150vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 10;

}
.searchFilter{
  position: absolute;
  top: 5px;
  width: 250px;
  right: 200px;
  .inputAndListContainer{
    width: 100%;
    .input-wrapper{
      min-height: unset;

      div:first-child{
        min-height: unset;
        max-height: 40px;
        input{
          height: 40px;
        }
        span{
          top: 12px;
        }
      }
    }
  }
  .searchList{
    position: relative;
    z-index: 5;
    border: solid 1px var(--light-blue);
    border-radius: 5px;
    overflow: hidden;
    .itemContainer{
      border-bottom: solid 1px var(--light-blue);
      padding: 5px;
      max-width: 250px;
      background-color: white;
      p{
        margin: 0;
        font-weight: 500;
        &:not(:last-child){
          border-bottom: solid 1px var(--light-blue);
        }
      }
    }
  }
}
h5.agreementCreationDate {
  font-size: 18px;
  color: var(--dark-blue);
  text-align: right;
}