.user-avatar {
    min-height: 65px;
    justify-content: space-between;
    padding-right: 20px;
    box-shadow: 2px 0px 6px 0px rgba(0,0,0,0.4);
    -webkit-box-shadow: 2px 0px 6px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 2px 0px 6px 0px rgba(0,0,0,0.4);
    .notifications {
        .notification {
            margin: 0 25px;
            .notification-icon {
                width: 20px;
            }
            &:nth-child(2) {
                margin: 0;
            }
        }
        .name-avatar {
            cursor: pointer;
            .name {
                font-size: 16px;
                font-weight: bold;
                //border-left: 1px solid #EBEBF2;
                height: 40px;
                line-height: 40px;
                color: var(--dark);
            }
            .avatar {
                .avatar-img {
                    width: 35px;
                    margin: 0 20px;
                    height: 35px;
                    border-radius: 100%;
                }
            }
        }
        .dropdown-items {
            width: 250px;
            position: absolute;
            top: 50px;
            left: 0;
            border-radius: 0 0 10px 10px;
            //overflow: hidden;
            box-shadow: 0 10px 10px 0 rgba(185, 185, 185, 0.2);
            background-color: #fff;
            z-index: 99999;
            border: solid 1px black;
            border-top: none;
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    text-align: center;
                    margin: 8px 0;
                    font-size: 16px;
                    color: var(--dark-blue);
                    cursor: pointer;
                    &:first-child {
                        border-bottom: 1px solid #34366d66;
                        padding-bottom: 8px;
                    }
                    .logout-icon {
                        color: var(--dark-blue);
                        width: 20px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}