@import '../../App.scss';

.transportContainer{
  display: flex;
  .formWrapper{
    width: 80%;
  }
  .transportFormContainer-right{
    width: 20%;
    padding: 0 5px;
    @include breakpoint(xs){
      width: 100%;
      justify-content: flex-start !important;
    }
  }
  .wrappedTextArea{
    @include breakpoint(sm){
      flex-wrap: wrap;
    }
  }
  .mapContainer{
    width: 100%;
    height: 80vh;
    overflow: hidden;
    @include breakpoint(xs){
      width: 100%;
      margin-bottom: 10px;
    }
    .map{
      width: 100%;
      height: 100%;
      .leaflet-control-zoom.leaflet-bar.leaflet-control{
        a{
          justify-content: center !important;
        }
      }
    }
  }
  .leaflet-control-attribution{
    display: none;
  }
  .transportFormContainer-left{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .projectFormForTransport{
      margin-right: 10px;
      width: 49%;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 15px;
      position: relative;
      .title{
        display: flex;
        width: 85%;
      }
      &:not(.alert.alert-success){
        border: solid 1px #0061A8;
        background-color: #f8faff;
      }
      &.alert.alert-success{
        background-color: rgba(209, 231, 221, 0.4);
      }
      .fileContainer{
        display: flex;
        flex-direction: row-reverse;
        position: absolute;
        top: 10px;
        right: 10px;
        .fileIcon{
          span{
            font-size: 25px;
            padding: 5px;
          }
        }
        .mapIcon{
          span{
            font-size: 25px;
            padding: 5px;
          }
        }
        .expandArrowIconContainer{

          span{
            font-size: 25px;
            padding: 5px;
          }
        }
      }
      @include breakpoint(xs){
        width: 100%;
        margin-right: 0;
      }
    }
  }
  .mapAndEquipmentsContainer{
    display: flex;
    @include breakpoint(xs){
      flex-direction: column;
    }
  }
  .transportFormContainer-left{
    @include breakpoint(sm){
      width: 100%;
    }
    label{
      padding: 5px;
    }
  }

  .transportFormContainer-right{
    @include breakpoint(sm){
      width: 30%;
    }
  }


  .equipmentsCheckboxContainer{
    margin-top: 20px;
    height: fit-content;
    overflow: auto;
    position: sticky;
    top: 10px;
    padding: 10px 15px;
    background-color: white;
    border: solid 1px var(--dark-blue);
    border-radius: 10px;

    @include breakpoint(sm){
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-height: 0px;
    }
  }
  .transportEquipmentListHeader{
    .titleContainer{
      p{
        font-size: 20px;
        font-weight: bold;
        color: var(--dark-blue);

        @include breakpoint(sm){
          font-size: 10px;
        }
      }
    }
  }
  .transportEquipmentListContent{
    padding: 10px 0;
    p{
      margin: 0;
      font-size: 16px;
      color: var(--main);
      font-weight: bold;
    }
    .input-wrapper{
      margin: 0;
      min-height: fit-content;
      label{
        i{
          border-radius: 50%;
          border-width: 3px;
        }
      }
    }
  }  

  .transportEquipmentListContent{
    @include breakpoint(sm){
      margin-bottom: 56px;
    }
  }
}

.mobileForm{
  @include breakpoint(sm){
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
  }
  @include breakpoint(md){
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
  }
}
.projectMultiSelectContainer{
  width: 100%;
  margin: 20px 0;
  position: relative;
  z-index: 11111111111;
}
.googleMapsLink{
  margin-top: 10px;
  font-size: 12px !important;
}

.projectFormForTransport{

}
.warehouseProductsContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .warehouseProductRow{
    border-top: solid 1px var(--dark-blue);
    p{
      color: var(--dark-blue);
      margin: 10px 0;
    }
    &.bold{
      p{
        font-weight: bold;
      }
    }
    width: 100%;
    display: flex;
    justify-content: space-between;

  }
}
.transportDateInputWrapper{
  .clearDate{
    width: 20px;
    height: 20px;
    top: 60%;
    background-color: transparent;
    .x-sign{
      font-size: 30px;
      color: black;
    }
  }
  .pinLogoForMeasure{
    padding: 2px;
  }
  input[name="orderAcceptationDate"],input[name="orderAcceptanceDate"]{
    padding: 5px 10px !important;
    height: 30px!important;
  }
}
.transportSaveButton{
  width: 100%;
}