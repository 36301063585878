.mikaPasswordInputWrapper{
  min-height: 50px;
  margin-bottom: 20px;
  position: relative;
  input{
    width: 100%;
    //min-height: 100%;
    font-size: 16px;
    color: var(--main);
    padding: 15px;
    &:focus{
      outline: none;
    }
  }
  .togglePasswordIcon{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 55px;
    width: auto;
    padding: 15px;
    cursor: pointer;
    &.notHidden{
      filter: invert(0.5);
    }
  }
}