.left-navbar {
    width: 250px!important;
    height: calc(100vh - 65px);
    @media screen and (max-width: 1030px){
        height: calc(111vh - 55px);
    }
    background-color: var(--dark);
    display: flex;
    justify-content: space-between;
    transition: 100ms;
    flex-direction: column;
    overflow: scroll;
    &::-webkit-scrollbar{
        height: 0px;
        width: 0px;
    }
    &.collapsed {
        width: 65px !important;
        overflow: hidden;
    }
    a{
        color: inherit;
        text-transform: capitalize !important;
        font-family: inherit !important;
        text-decoration: none;
        line-height: unset !important;
    }
    ul {
        list-style: none;
        padding-left: 10px;
        margin: 0;
        .row-li {
            position: relative;
            margin: 20px 0;
            cursor: pointer;
            .circle {
                border-radius: 50%;
                width: 40px;
                height: 40px;
                margin: 2px 20px 0 0;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                background-color: var(--light-blue);
                img{
                    width: 70%;
                    height: 70%;
                    object-fit: contain;
                }
            }
            .border-circle {
                position: absolute;
                top: -3px;
                left: -5px;
                width: 50px;
                height: 50px;
                border: 1px solid var(--light-blue);
                border-radius: 25px;
            }
            .title {
                font-size: 18px;
                font-weight: 500;
                color: var(--light-blue);
            }
            &.active,
            &:hover  {
                .border-circle {
                    width: 200px!important;
                    // transition: ease-in .3s;
                    transition: width .3s linear .1s;
                }
            }
        }
    }
    .admin-panel{
        width: 100%;
        height: 100%;
        background-color: var(--light-blue);
        padding-top: 5px;
        transition: ease-out .3s;

        p{
            width: 100%;
            margin: 0;
            text-align: center;
        }
        ul {
            padding: 3px 15px;
            .row-li {
                margin: 14px 0;
                .title {
                    line-height: 1.6;
                }
                .circle {
                    margin-right: 10px;
                }
                .border-circle {
                    &:hover {
                        width: 220px!important;
                    }
                }
            }
        }
        .go-to-system {
            width: 100%;
            background-color: var(--dark-blue);
            position: absolute;
            bottom: 0;
        }
    }
    .admin-nav{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        padding: 5px 10px;
        img{
            width: 30px;
            height: 30px;
            margin: 5px 15px;
        }
        p{
            margin: 0;
        }
        a{
            width: fit-content;
            height: fit-content;
            padding-bottom: 5px;
            transition: 200ms !important;
            &:hover{
                img{
                    filter: grayscale(1);
                }
                border-radius: 3px;
                .circle {
                    border-width: 8px;
                }
            }
        }
        .circle {
            border: 2px solid var(--dark-blue);
            border-radius: 50%;
            width: 16px;
            height: 16px;
            margin-right: 15px;
            transition: 200ms;
            &.closed{
                margin-left: 13px;
            }
        }
        .title {
            font-size: 16px;
            font-weight: 500;
            color: var(--dark-blue);
            line-height: 1.5;
            text-transform: initial;
        }
        
    }
}
.sub-menu-container{
    flex-wrap: wrap;
}
.sub-menu{
    width: 100%;
    color: var(--light-blue);
    padding: 10px 0 0 55px !important;
    font-size: 14px;
    a{
        text-transform: capitalize !important;
        font-size: 15px !important;
        color: var(--light-blue) !important;
        &:hover{
            color: #7974ff !important;
        }
    }
    li{
        padding: 2px 0;
        position: relative;
        text-transform: initial;
        &::before{
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            border: 1px solid var(--light-blue);
            position: absolute;
            top: 40%;
            left: -10px;
        }
    }
}
.height-stretch{
    height: stretch;
}
@media screen and (max-width: 500px) {
    .left-navbar{
        width: 100% !important;

        ul {
            .row-li {
                position: relative;
                margin: 20px 0;
                cursor: pointer;
                .circle {
                    border: 3px solid var(--light-blue);
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                    margin: 2px 20px 0 0;
                }
                .border-circle {
                    position: absolute;
                    top: -3px;
                    left: -5px;
                    width: 35px;
                    height: 35px;
                    border: 1px solid var(--light-blue);
                    border-radius: 100px;
                }
                .title {
                    font-size: 20px;
                    font-weight: 500;
                    color: var(--light-blue);
                }
                &.active,
                &:hover  {
                    .border-circle {
                        width: calc(100% - 10px)!important;
                        border-radius: 20px!important;
                        transition: 100ms;
                    }
                }
            }
        }
    }
}