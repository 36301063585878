.signature-canvas {
  border: 1px solid var(--light-blue);
  width: 100%;
  border-radius: 8px;
}
.signatureWrapper{
  margin-top: 10px;
  button{
    border: none;
    background-color: var(--dark-blue);
    color: white;
    padding: 10px 20px;
    width: 50%;
    transition: 200ms;
    &:only-child{
      border-radius: 8px !important;
      width: 100%;
    }
    &:hover{
      opacity: 0.9;
    }
    &:first-child{
      border-radius: 8px 0 0 8px;
    }
    &:not(:first-child){
      border-radius: 0 8px 8px 0;
    }
  }
}