@import '../../App.scss';

.table-responsive::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
.table-responsive::-webkit-scrollbar-track {
    background: #E3E3E3;
    border-radius: 3px;
}
.table-responsive::-webkit-scrollbar-thumb {
    background: #C8C7C7;
    border-radius: 3px;
}
// .table-responsive {
//     height: calc(100% - 50px);
// }
.table {
    display: flex;
    flex-direction: column;
    //width: max-content;
    .thead {
        .tr {
            min-height: 40px;
            .th {
                font-size: 18px;
                font-weight: 500;
                color: var(--dark-blue);
            }
        }
    }
    .tbody {
        height: calc(100vh - 370px);
        //overflow-x: auto;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        .tr {
            .td {
                font-size: 16px;
                font-weight: 500;
                color: var(--main);
                border-bottom: 1px solid var(--light-blue);
                .btn {
                    padding: 0;
                    img {
                        width: 20px;
                    }
                }
                p{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    text-transform: capitalize;
                }
            }
        }
        .spinner {
            position: absolute
        }
    }
    .tr {
        display: flex;
        min-height: 50px;
        .th,.td {
            width: 23%;
            min-width: 23%;
            &:first-child,
            &:last-child {
                width: 60px;
                min-width: 60px;
                max-width: 60px;
                display: flex;
                justify-content: center;
                cursor: pointer;
                .table-border-light {
                    display: flex;
                    justify-content: center;
                }
            }
            &:first-child {
                border: 0;
            }
            &:last-child {
                .table-border-light {
                    border: 0;
                }
            }
            &:nth-child(12) {
                padding-right: 0;
                .table-border-light {
                    padding-right: 8px;
                }
            }
            .table-border-light {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                border-right: 1px solid var(--light-blue);
                span {
                    margin-right: 10px;
                }
            }
        }
        &:last-child {
            .td {
                border-bottom: 0;
            }
        }
    }    
    .table-border-right {
        padding: 10px;
        height: 82px;
    }

    &.table-without-actions {
        .tr {
            .th,.td {
                &:first-child,
                &:last-child {
                    width: 32%;
                    justify-content: flex-start;
                }
            }
            .td {
                &:first-child {
                    border-bottom: 1px solid var(--light-blue);
                    padding-left: 0;
                    .table-border-light {
                        border-left: 1px solid var(--light-blue);
                        justify-content: start;
                        padding-left: 15px;
                    }
                }
                &:last-child {
                    padding-right: 0;
                    .table-border-light {
                        border-right: 1px solid var(--light-blue);
                        justify-content: start;
                        padding-right: 15px;
                    }
                }
            }
            &:first-child {
                .th {
                    &:first-child  {
                        padding-left: 15px;
                    }
                }
            }
            &:last-child {
                .td {
                    &:first-child {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
    &.table-offer{
        .thead,.tbody{
            .tr{
                .td:first-child , .td:last-child , .th:first-child , .th:last-child{
                    width: 80px !important;
                    .table-border-light{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center !important;
                        padding: 0;
                    }
                }
            }
        }
    }
    &.adminTableList{
        .thead,.tbody{
            .tr{
                .td, .th{
                    width: 30vw !important;
                }
            }
        }
    }
    &.articleTableList{
        .thead,.tbody{
            .tr{
                .td, .th{
                    &:nth-child(2){
                        min-width: 60px;
                        max-width: 60px;
                    }
                    width: 30vw !important;
                }
            }
        }
    }
    &.warehouseTable{
        .thead,.tbody{
            .tr{
                .td, .th{
                    min-width: 15vw;
                    max-width: 15vw;
                    width: 15vw !important;
                    &:not(.th){
                        border-bottom: 1px solid var(--light-blue);
                    }
                    &:not(.td){
                        p{
                            width: 100%;
                        }
                    }
                    .table-border-light{
                        text-align: left;
                        p{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.import-icon {
    width: 24px;
}
.productionProducts{
    .full-height-container {
        > div:nth-child(2) {
            height: calc(100vh - 120px);
        }
    }
}
.full-height-container {
    > div:nth-child(2) {
        height: calc(100vh - 150px);
    }
}
.containsOneActions{
    .MuiTable-root{
        .MuiTableRow-root{
            .button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit:first-child{
                width: 100% !important;
            }
        }
    }

}
.MuiTable-root{
    .MuiTableRow-root{
        position: relative;
        th.MuiTableCell-root, td.MuiTableCell-root{
            //display: none;
            &:first-child:not(&:only-child){
                //width: 10px !important;
                //min-width: 10px !important;
                //max-width: 10px !important;
            }
        }
    }
    @media screen and (min-width: 500px) {
        button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit:nth-child(2){
            margin-left: auto;
        }
        button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit:first-child{
            position: absolute;
            width: calc(100% - 50px);
            top: 0;
            left: 0;
            display: flex;
            justify-content: flex-end;
            border-radius: 0 15px 15px 0;
            height: 100%;
            z-index: 1;

            @include breakpoint(sm){
                // width: 96%;
                opacity: 0;
            }

            @include breakpoint(xs){
                // width: 96%;
                opacity: 0;
            }
        }
    }
}
.allModuleContent{
    .MuiTable-root{
        button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit:first-child{
            width: 100% !important;
        }
    }
}
.productionTable{
    .MuiTable-root{
        button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit:first-child{
            width: 100% !important;
        }
    }
}


.MuiToolbar-root {
    @include breakpoint(xs){
      flex-wrap: wrap;
    }
      &.MuiToolbar-gutters {
          padding-right: 50px;
          @include breakpoint(xs){
            padding-right: 0;
          }
      }
      &.MuiTablePagination-toolbar {
          padding-right: 0;
      }

      div:first-child{
        @include breakpoint(xs){
          width: 100%;
        }
      }
      div:nth-child(2){
        @include breakpoint(xs){
          display: none;
        }
      }

      div:nth-child(3){
        @include breakpoint(xs){
          padding-left: 0;
          width: 80%;
        }

        div:nth-child(3){
            @include breakpoint(xs){
                flex-basis: 10px !important; 
            }
        }
      }

      div:nth-child(4){
        @include breakpoint(xs){
          width: 20%;
        }
      }
    }

button.MuiButtonBase-root.MuiIconButton-root{
    span.MuiIconButton-label{
        pointer-events: none;
    }
}