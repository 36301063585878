:root {
    --main: #707070;

    --red: #D01314;
    --yellow: #b1b13a;
    --orange: #ff9000;
    --green: #32BC20;
    --blue: #3788d8;

    --light-grey: #BCBCCB;
    --purple: #A3A0FB;
    --light-blue: #A5C9FF;

    --dark: #252434;
    --dark-blue: #34366D;
    --blueish: rgba(52, 54, 109, 0.8);

    --light-purple: #E0DFF5;
    --medium-purple: #C3C1F8;

    --button-grey: #EEEEEE;

    --pagination-gray: #c2c1c1;
}