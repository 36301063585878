.task-form{
  background-color: white;
  border-radius: 8px;
  padding: 5px 20px ;
  position: relative;
  .x-sign{
    position: absolute;
    top: 5px;
    right: 5px;
    transform: rotate(45deg);
    cursor: pointer;
  }
  .actionButton{
    background-color: var(--dark-blue);
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 8px;
    font-weight: bold;
    color: white;
    border: none;
    transition: 300ms;
    &:hover{
      background-color: var(--purple);
    }
  }
  .editViewButtons{
    width: 100%;
    display: flex;
  }
}