.usersDashboard{
  .statusLabel{
    font-size: 20px;
    border-radius: 8px;
    font-weight: 500;
    padding: 5px 10px;
    border: solid 1px;
    margin: 0;
    line-height: 20px;
    margin-top: 5px;
    text-align: center;
    &.active{
      background-color: #EAF8E8;
      color: var(--green);
      border-color: var(--green);
    }
    &.nonActive{
      background-color: #F6F9FF;
      color:  var(--blue);
      border-color:  var(--blue);

    }
    &.passive{
      background-color: #FAE7E7;
      color: var(--red);
      border-color: var(--red);
    }
  }
  .userActivityModal-wrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150vw;
    height: 150vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1111;
    .userActivityModal{
      position: relative;
      background-color: white;
      border-radius: 8px;
      min-width: 500px;
      max-height: 600px;
      overflow: hidden auto;
      padding: 35px 10px 20px 10px;
      .x-sign{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        img{
          transform: rotate(45deg);
        }
      }
      .userActivityContent{
        .listedActivities{
          .status-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: solid 1px var(--dark-blue);
            padding: 10px 0;
            p{
              margin: 0;
            }
            .projectProductNames{
              width: 50%;
            }
            .status-box{
              border-radius: 8px;
              padding: 5px 10px;
              border: solid 1px;
              &.completed{
                background-color: #EAF8E8;
                color: var(--green);
                border-color: var(--green);
              }
              &.nonActive{
                background: linear-gradient(80deg, rgba(50,188,32,0.5) -5%, rgba(55,136,216,0.5) 100%);
                border-color: var(--blue);
              }
              &.toDo{
                background-color: #F6F9FF;
                color:  var(--blue);
                border-color:  var(--blue);
              }
              &.late{
                background-color: #FAE7E7;
                color: var(--red);
                border-color: var(--red);
              }
              &.inProcess{
                background-color: #FCFCE5;
                color: var(--yellow);
                border-color: var(--yellow);
              }
              &.paused{
                background-color: #FCFCE5;
                color: var(--orange);
                border-color: var(--orange);
              }
              &.otherWork{
                background-color: var();
                color: var(--blueish);
                border-color: var(--blueish);
              }
            }
          }
        }
      }
    }
  }
}