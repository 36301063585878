@import '../../App.scss';

.contact {
  .wrapper {
    .import-section {
      position: absolute;
      z-index: 99999;
      top: 20px;
      right: 40px;
    }

    .mobileDisplay{
      @include breakpoint(xs){
        display: none;
      }
    }
    .create-client {
      width: 100%;

      .create-client-row{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 3rem;

        @include breakpoint(xs){
          padding-left: 15px;
          margin-bottom: 20px !important;
          flex-direction: row-reverse;
        }
      }
      .create-new-text{
        @include breakpoint(xs){
          margin: 0 !important;
          p{
            margin-bottom: 0;
          }
          .createClientText{
            display: none;
          }
        }
      }
      .social-networks-text {
        color: var(--main);

        span {
          font-weight: 500;
        }
      }

      &-row {
        margin-left: -10px;
      }

      .textarea-wrapper {
        height: 240px;

        textarea {
          height: 100%;

          @include breakpoint(sm){
            height: 85%;
          }
        }
      }

      .pr-15 {
        padding-right: 15px !important;
      }

      .pl-15 {
        padding-left: 15px !important;
      }

      .select-input {
        height: 50px;
        margin-bottom: 40px;
        &.multiSelect{
          height: unset;
          margin-bottom: 20px;
        }
      }

      .select-input .react-select__control {
        min-height: 49px;
        border: 1px solid var(--light-blue);
        border-radius: 10px;
        padding-left: 12px;
        color: var(--main);
        font-size: 16px;
        font-weight: 500;
      }
      .created-at-date {
        p {
          padding: 0px 10px;
          font-weight: 500;
          color: var(--dark-blue);
        }
      }

      .mobileWidth{
        @include breakpoint(xs){
          width: 100% !important;
        }
      }

      .mobileAlign{
        @include breakpoint(xs){
          justify-content: flex-end !important;
          flex-direction: column;
          gap: 20px;
        }
      }
    }

    .upload-client-wrapper {
      height: 58px;

      .upload-client {
        width: 185px;
        height: 30px;
        margin: auto;
        margin-right: 0;
        min-width: 50px;
        padding: 8px 13px;
        padding: 8px 13px;
        border: 0;
        background-color: transparent;

        .inputFileWrapper {
          width: 100%;
          height: 100%;
        }

        label {
          align-items: center;

          span {
            &:nth-child(1) {
              font-size: 14px!important;
              text-align: right;
              padding-right: 8px;
            }

            &:nth-child(2) {
              font-size: 14px;
            }
          }

          &:nth-child(2) {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }
      }

      .basic-link {
        margin-left: 10px !important;
        margin-top: 17px !important;
        .import-icon {
          width: 22px;
          fill: #484848;
        }
      }
    }

   
    
    .questionary-title {
        font-weight: 500;
    }
  }

  .suggestedClients-container-measures {
    top: calc(100% - 40px);
    left: 50%;
    z-index: 199;
    width: 93%;
    height: max-content;
    max-height: 300px;
    overflow-y: auto;
    border: solid 1px var(--light-blue);
    border-radius: 0 0 10px 10px;
    transform: translateX(-50%);
  }

  .searchContainer {
    width: 51%;
    //position: absolute;
    //left: 56%;
    //top: 19%;
    //transform: translate(-50%, -50%);
    z-index: 1;

    @include breakpoint(xs){
      width: 100%;
    }
    .suggestedClients-container-measures {
      width: 100%;
      top: 100%;
      left: 50%;
      z-index: 199;
      height: max-content;
      max-height: 300px;
      overflow-y: auto;
      border: solid 1px var(--light-blue);
      border-radius: 0 0 10px 10px;
      transform: translateX(-50%);
      padding: 0;
    }

    .searchIcon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
    }


    input {
      background-color: transparent;
    }

    .mb-bottom {
      margin-top: 43px;
    }
  }
  .inputLabelOutside {
    padding: 4px 12px;
    margin-bottom: 0;
    font-weight: 500;
    color: var(--dark-blue);
  }
}
.productPictureContainer{
  padding: 30px 4px 10px 20px;
  a{
    width: fit-content;
    text-decoration: none;
    margin-bottom: 10px;
  }
}
